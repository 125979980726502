import React, { useState } from "react"
import Layout from "../templates/Layout"
import Seo from "../components/seo"
import styled, { keyframes } from "styled-components"
import textBottomGreen from "../assets/text-bottom-green.svg"
import textBottomGreyMobile from "../assets/text-bottom-grey-mobile.svg"
import Paragraph from "../components/typography/Paragraph"
import HeadingH2 from "../components/typography/HeadingH2"
import signatureSrc from "../assets/signature.png"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactVivus from "react-vivus"
import Rotate from "react-reveal/Rotate"
import Swing from "react-reveal/Swing"
import Fade from "react-reveal/Fade"
import Menu from "../components/menu/Menu"
import getHtmlFromRedactorField from "../utils/getHtmlFromRedactorField"
import { useSelector, useDispatch } from "react-redux"
import {
  setActivePatternMenu,
  setActiveLanguage,
  setActivePage,
} from "../store/menuSlice"

const StyledBackgroundWrapper = styled.div`
  min-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  background-color: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1023px) {
    height: auto;
  }
  @media (max-width: 810px) {
    height: 100vh;
  }
  @media (max-width: 500px) {
    height: auto;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    /* position: relative; */
  }
`

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 10%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    height: auto;
    justify-content: flex-start;
    margin-top: 100px;
    padding: 0;
  }
`

const StyledPhotoWrapper = styled.div`
  width: 45%;
  height: auto;
  padding-right: 50px;
  @media (max-width: 1443px) {
    padding-right: 0px;
  }
  @media (max-width: 1024px) {
    width: 70%;
    padding-right: 0px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`

const StyledCopyWrapper = styled.div`
  width: 55%;
  height: 100%;
  text-align: right;
  padding-right: 150px;
  padding-top: 7vh;
  position: relative;
  z-index: 2;
  @media (max-width: 2000px) {
    padding-top: 0vh;
    bottom: 4%;
  }
  @media (max-width: 1450px) {
    padding-top: 4vh;
    bottom: 0%;
  }
  @media (max-width: 1300px) {
    padding-top: 0vh;
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0px;
    padding-top: 12vh;
  }
  @media (max-width: 1023px) {
    padding-bottom: 5vh;
  }
  @media (max-width: 810px) {
    padding-top: 16vh;
  }
  @media (max-width: 500px) {
    padding-bottom: 0vh;
    padding: 0 10%;
    margin-bottom: 120px;
    bottom: 0%;
  }
`

const StyledTextBottom = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
`

const StyledTextBottomMobile = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: none;
  @media (max-width: 500px) {
    display: block;
    position: fixed;
  }
`
const rotate = keyframes`
  from {
    transform: rotate(-90deg);
opacity:0;
  }

  to {
    transform: rotate(0deg);
opacity:1
  }
  
`

const StyledRectangle = styled.div`
  width: 500px;
  height: 500px;
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.green};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  margin: auto;
  &.solid {
    transform: rotate(0deg);
    opacity: 1;
  }
  &.animation {
    animation: ${rotate} 0.9s ease-in-out both;
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
`

const StyledGatsbyImage = styled.div`
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transition: filter 0.5s;
  &:hover {
    filter: invert(1);
  }
`

const signature = keyframes`
  from {
opacity:0;
  }

  to {
    opacity:1;
  }
`

const StyledSignature = styled.img`
  width: 162px;
  height: auto;
  position: absolute;
  right: 150px;
  animation: ${signature} 0.6s 0.2s ease-in-out both;
  @media (max-width: 1443px) {
    width: 116px;
  }
  @media (max-width: 1024px) {
    right: 0px;
    width: 162px;
  }
  @media (max-width: 850px) {
    width: 100px;
  }
  @media (max-width: 426px) {
    width: 90px;
  }
`

const StyledButtonModule = styled.div`
  display: flex;
  position: absolute;
  z-index: 20;
  bottom: -28px;
  align-items: center;
  left: 0;
  right: 0;
  margin: 0 auto;
`

const StyledButtonInfo = styled.h4`
  font-size: 18px;
  line-height: 22px;
  font-family: "Lato";
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const StyledButton = styled.a`
  font-size: 41px;
  line-height: 49px;
  font-family: "Yrsa";
  letter-spacing: 0px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  @media (max-width: 1440px) {
    font-size: 31px;
    line-height: 37x;
  }
`

const pattern = keyframes`
  from {
      width:1vw;
      height:auto;
      opacity:1;
    }
  to {
    width:100vw;
    height:auto;
    opacity:0.03
  }
`

const StyledPattern = styled.img`
  width: 500px;
  height: 500px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  margin: auto;
  &.animation {
    animation: ${pattern} 0.5s linear both;
  }
  &.solid {
    width: 100vw;
    height: auto;
    opacity: 0.03;
  }
`

const PageName = styled.p`
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  transition: transform 0.3s ease-in-out;
  position: fixed;
  color: white;
  top: 51px;
  right: 100px;
  z-index: 11;
  display: none;
  @media (max-width: 1030px) {
    display: block;
  }
`

const StyledLinkWrapper = styled.div`
  margin-right: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -15px;
  @media (max-width: 1200px) {
    margin-right: 0px;
  }
`

const HoverSocialWrapper = styled.div`
  height: max-content;
  height: 25px;
  position: relative;
  /* width: max-content; */
`

const StyledSocialLink = styled.div`
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  img:last-child {
    /* position: relative;
    bottom: -2px; */
    display: none;
  }
  &:hover {
    /* filter: invert(89%) sepia(13%) saturate(1247%) hue-rotate(109deg)
      brightness(99%) contrast(89%); */
    img:last-child {
      display: block;
    }
    img:first-child {
      display: none;
    }
  }
`

const Contact = ({ data, location }) => {
  const fromHome = location?.state?.fromHome || ""
  const menuData = data?.datoCmsMenu || ""
  const moduleData = data?.datoCmsContact || ""

  const headline = moduleData?.headline || ""
  const background = moduleData?.backgroundphoto?.url || ""
  const photo = moduleData?.mainphoto?.fluid || ""
  const pageName = moduleData?.pagename || ""
  const contactBlocksArray = moduleData?.contactblock || []
  const pattern = moduleData?.menupattern?.url || ""
  const rowTwoHover = moduleData?.rowtwohover || ""
  const rowThreeText = moduleData?.rowthreetext || ""
  const rowThreeHover = moduleData?.rowthreehover || ""
  const rowOneText = moduleData?.rowonetext || ""
  const rowOneHover = moduleData?.rowonehover || ""
  const rowFiveText = moduleData?.rowofivetext || ""
  const rowFourText = moduleData?.rowfourtext || ""
  const rowFourHover = moduleData?.rowfourhover || ""
  const rowFiveHover = moduleData?.rowfivehover || ""
  const rowTwoText = moduleData?.rowtwotext || ""
  const menuPattern = moduleData?.menupattern?.url || ""
  const globalData = data?.datoCmsGlobal || ""

  const dispatch = useDispatch()
  dispatch(setActivePage("contact"))
  dispatch(setActiveLanguage("en"))
  dispatch(setActivePatternMenu(menuPattern))

  const [activeRowOne, setActiveRowOne] = useState(rowOneText)
  const [activeRowTwo, setActiveRowTwo] = useState(rowTwoText)
  const [activeRowThree, setActiveRowThree] = useState(rowThreeText)
  const [activeRowFour, setActiveRowFour] = useState(rowFourText)
  const [activeRowFive, setActiveRowFive] = useState(rowFiveText)
  const [socialIcon, setSocialIcon] = useState("")

  return (
    <Layout>
      <Seo title={pageName} />
      <PageName>{pageName}</PageName>
      <StyledPattern
        className={fromHome ? "animation" : "solid"}
        src={pattern}
      />
      <Menu isWhite={true} data={menuData} globalData={globalData} />
      <StyledBackgroundWrapper>
        <StyledRectangle className={fromHome ? "solid" : "animation"} />

        <StyledContentWrapper>
          <StyledCopyWrapper>
            <HeadingH2 className="contact">{headline}</HeadingH2>

            <div
              onMouseOver={() => setActiveRowOne(rowOneHover)}
              onMouseOut={() => setActiveRowOne(rowOneText)}
            >
              <Paragraph className="contact">{activeRowOne}</Paragraph>
            </div>

            <div
              onMouseOver={() => setActiveRowTwo(rowTwoHover)}
              onMouseOut={() => setActiveRowTwo(rowTwoText)}
            >
              <Paragraph className="contact">{activeRowTwo}</Paragraph>
            </div>

            <div
              onMouseOver={() => setActiveRowThree(rowThreeHover)}
              onMouseOut={() => setActiveRowThree(rowThreeText)}
            >
              <Paragraph className="contact">{activeRowThree}</Paragraph>
            </div>

            <HoverSocialWrapper
              onMouseEnter={() => setActiveRowFour("socials")}
              onMouseLeave={() => setActiveRowFour(rowFourText)}
            >
              <Paragraph className="contact">
                {activeRowFour.length > 10 ? rowFourText : ""}
              </Paragraph>
              <StyledLinkWrapper>
                {activeRowFour === "socials" &&
                  rowFourHover.map((item, key) => {
                    const link = item?.link || ""
                    const icon = item?.icon?.url || ""
                    const iconHover = item?.iconhover?.url || ""

                    return (
                      <StyledSocialLink as="a" href={link} target="blank">
                        <img src={icon} />
                        <img src={iconHover} />
                      </StyledSocialLink>
                    )
                  })}
              </StyledLinkWrapper>
            </HoverSocialWrapper>

            <div
              onMouseOver={() => setActiveRowFive(rowFiveHover)}
              onMouseOut={() => setActiveRowFive(rowFiveText)}
            >
              <Paragraph className="contact">{activeRowFive}</Paragraph>
            </div>
          </StyledCopyWrapper>
          <StyledPhotoWrapper>
            <StyledGatsbyImage>
              <Img fluid={photo} />
            </StyledGatsbyImage>
          </StyledPhotoWrapper>
        </StyledContentWrapper>

        <StyledTextBottom src={textBottomGreen} />

        <StyledTextBottomMobile src={textBottomGreyMobile} />
      </StyledBackgroundWrapper>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query contactQuery {
    datoCmsGlobal {
      languages
      socialmedia {
        name
        link
      }
      logo {
        url
      }
    }
    datoCmsContact {
      headline
      pagename
      rowtwohover
      rowthreetext
      rowthreehover
      rowonetext
      rowonehover
      rowofivetext
      rowfourtext
      rowfourhover {
        iconhover {
          url
        }
        icon {
          url
        }
        link
      }
      rowfivehover
      rowtwotext
      menupattern {
        url
      }
      mainphoto {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsMenu {
      _allMenuitemLocales {
        locale
      }
      menuitem {
        pagelink {
          __typename
          ... on DatoCmsContact {
            pagename
            menupattern {
              url
            }
          }
          ... on DatoCmsWork {
            pagename
            menupattern {
              url
            }
          }
          ... on DatoCmsHomepage {
            id
          }
          ... on DatoCmsAbout {
            pagename
            menupattern {
              url
            }
          }
        }
      }
    }
  }
`
